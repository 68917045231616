
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseAutoComplete from '@/components/Base/BaseAutoComplete.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import constRouter from '@/constants/constRouter';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { required } from '@/helpers/validate';
import { getProvinces, getDistrict, getWard } from '@/apis/ApiGeneral';
import { defineComponent } from 'vue';
import { updateCustomer } from '@/apis/ApiOnboarding';
import { mapGetters, mapActions } from 'vuex';
export default defineComponent({
  components: {
    DefaultLayout,
    BaseAutoComplete,
    BaseButton,
    BaseInput,
    BaseTextError,
  },
  name: 'BorrowerAddress',
  data() {
    return {
      province: '',
      provinceId: '',
      errorProvince: '',
      district: '',
      districtId: '',
      errorDistrcit: '',
      ward: '',
      wardId: '',
      errorWard: '',
      street: '',
      errorStreet: '',
      houseNumber: '',
      errorHouseNumber: '',
      telephone: '',
      errordateSalary: '',
      // moneyInsurance: '',
      arrProvince: [],
      arrDistrict: [],
      arrWard: [],
      arr: [
        {
          name: '',
          id: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUser: 'getUser',
    }),
  },
  created() {
    for (let i = 1; i <= 31; i++) {
      const item = {
        name: `Ngày ${i} hàng tháng`,
        id: i,
      };
      this.arr.push(item);
    }
    this.getProvice();
    this.provinceId = this.getUser.companyAddress?.province.id;
    this.districtId = this.getUser.companyAddress?.district.id;
    this.wardId = this.getUser.companyAddress?.ward.id;
    this.province = this.getUser.companyAddress?.province.name;
    this.district = this.getUser.companyAddress?.district.name;
    this.ward = this.getUser.companyAddress?.ward.name;
    this.street = this.getUser.companyAddress?.street;
    this.houseNumber = this.getUser.companyAddress?.houseNumber;
  },
  watch: {
    provinceId(value) {
      if (value) {
        getDistrict(value)
          .then(res => (this.arrDistrict = res.data.data))
          .catch(err => console.log(err));
      }
    },
    districtId(value) {
      if (value) {
        getWard(value)
          .then(res => (this.arrWard = res.data.data))
          .catch(err => console.log(err));
      }
    },
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    getProvice() {
      getProvinces()
        .then(res => {
          this.arrProvince = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    validation() {
      this.errorProvince = required('Province', this.province);
      this.errorDistrcit = required('District', this.district);
      this.errorWard = required('Ward', this.ward);
      this.errorStreet = required('Street', this.street);
      this.errorHouseNumber = required('HouseNumber', this.houseNumber);
      if (
        !this.province ||
        !this.district ||
        !this.ward ||
        !this.street ||
        !this.houseNumber
      ) {
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        updateCustomer('', {
          companyProvinceId: this.provinceId,
          companyDistrictId: this.districtId,
          companyWardId: this.wardId,
          companyStreet: this.street,
          companyHouseNumber: this.houseNumber,
          companyPhone: this.telephone,
        });
        this.fetchUserInfo();
        if (
          !this.getUser.nicFrontImageUrl ||
          !this.getUser.nicBackImageUrl ||
          !this.getUser.facePhotoImageUrl
        ) {
          this.$router.push({ name: constRouter.EKYC.name });
        } else {
          this.$router.push({ name: constRouter.BORROWER_HOUSE_HOLD.name });
        }
      }
    },
  },
});
